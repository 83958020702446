#container {
  margin: auto;
  position: relative;
  min-height: 100vh;
  width: 60%;
}

#main-wrapper {
  padding-bottom: 2.5rem;    /* Footer height */
  padding-top: 2.5rem;    /* breathing room */
  content: "";
  clear: both;
  display: table;
}

article {
  width: 30%;
  margin-right: 5%;
  float: left
}

aside {
  /* display: inline-block; */
  width: 60%;
  float: right
}

#footer {
  width: 100%;
  height: 2.5rem;            /* Footer height */
}