section {
  margin-top: 10px;
  border-top: solid #922610;
  border-bottom: solid #922610;
  background-color: #e1cca6;
}
.headshot-wrapper {
  background-image: url('../../assets/images/headshot.jpeg');
  box-shadow: 0 0 4rem #F1E9D2 inset;
  background-size: contain;
  background-repeat: no-repeat;
}
img {
  opacity: 0;
}