#footer {
    background-color: #79200f;
    text-align: center;
    
}

#footer p {
    font-weight: 500;
    color: white;
}
